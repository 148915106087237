import React from "react";
import { useSelector } from "react-redux";
import USER_ICON from "../../assets/images/detail-user.png";
import CLOCK_ICON from "../../assets/images/detail-clock.png";

const BlogDetailSection = () => {
  const { BlogDetail } = useSelector(
    (state) => state.blogDetail.BlogDetailsData
  );

  return (
    <>
      <div className="blog-detail">
        <div className="container">
          <h2>{BlogDetail?.BlogTitle}</h2>
          <p className="blog-post-time">
            {/* <span>
              <img src={USER_ICON} alt="User" />
              {BlogDetail.BlogDate}
            </span> */}
            <span>
              <img src={CLOCK_ICON} alt="Clock" />
              {BlogDetail?.BlogDate}
            </span>{" "}
          </p>
          <div className="blog-detail-img">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <img src={BlogDetail?.BlogImage} alt="Blog-detail" />
              </div>
              {/* <div className="col-md-6 col-sm-12">
                <img src={BLOG_DETAILS2} alt="Blog_detail" />
              </div> */}
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: BlogDetail?.LongDescription }}
          />
        </div>
      </div>
    </>
  );
};

export default BlogDetailSection;
