import React from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { closeDialog } from "../../components/dialog/dialogSlice";

const HomeImagePopupDialog = () => {
  const eventShow = useSelector((state) => state.dialogSlice.open);
  //   const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRedirectEvent = () => {
    dispatch(closeDialog());
    // navigate("/events/megaseminar/");
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.7)",
          zIndex: 999999,
          display: eventShow ? "block" : "none",
        }}
        className="overlay-modal"
      >
        {/* {eventShow && ( */}
        <div className="home-image-dialog">
          {/* <img
            src={"https://images.careerline.org/uploads/close.png"}
            height={50}
            className="event-popup-close"
            onClick={() => {
                dispatch(closeDialog());
                }}
          /> */}
          <span
            className="home-dialog-close"
            onClick={() => {
              dispatch(closeDialog());
            }}
          >
            X
          </span>
          {/* <div
            style={{ height: "100%", width: "100%", cursor: "pointer" }}
            onClick={handleRedirectEvent}
          /> */}
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default HomeImagePopupDialog;
