import React from "react";
import USER_ICON from "../../assets/images/detail-user.png";
import CLOCK_ICON from "../../assets/images/detail-clock.png";
import VOUCTURE_ICON from "../../assets/images/detail-voucture.png";
import { useNavigate } from "react-router";

const BlogListCard = ({ blog }) => {
  const navigate = useNavigate();

  const handleNavigate = (blog) => {
    if (blog?.BlogURL) {
      navigate(`/blogs/${blog?.BlogURL}/`);
    }
  };

  return (
    <div className="blog-post-column">
      <div className="blog-post-pic">
        <img src={blog?.BlogBannerImage} alt="" />
      </div>
      <div className="blog-post-con">
        <p className="blog-post-time">
          {/* <span>
            <img src={USER_ICON} alt="User" />
            {blog?.BlogDate}
          </span> */}
          <span>
            <img src={CLOCK_ICON} alt="Clock" />
            {blog?.BlogDate}
          </span>{" "}
          {/* <span>
            <img src={VOUCTURE_ICON} alt="Voucture" />
            {blog?.BlogDate} 
          </span> */}
        </p>
        <h2>
          <a> {blog?.BlogTitle}</a>
        </h2>
        <p>{blog?.ShortDescription}</p>
        <a className="btn btn-fill round" onClick={() => handleNavigate(blog)}>
          Read More
        </a>
      </div>
    </div>
  );
};

export default BlogListCard;
