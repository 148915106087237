import React, { useEffect } from "react";
import {
  Link,
  // Button,
  // Element,
  // Events,
  // animateScroll as scroll,
  scrollSpy,
} from "react-scroll";
// import Tabs from "./Tabs";
// import Content from "./Content";

const MainPackageTab = () => {
  // const [activeTab, setActiveTab] = useState("Overview");

  // const handleTabClick = (tab) => {
  //   setActiveTab(tab);
  // };
  useEffect(() => {
    scrollSpy.update();
  }, []);

  return (
    <div
      className="tour-page-tabing"
      style={{
        position: "sticky",
        top: 100,
        background: "#F9F9F9",
        padding: "10px 15px 0",
        zIndex: 98,
      }}
    >
      {/* <Tabs activeTab={activeTab} handleTabClick={handleTabClick} />
      <Content activeTab={activeTab} /> */}
      <div
        className="main-package-tab"
        style={{
          width: "100%",
          display: "flex",
          gap: 10,
          overflowX: "scroll",
          scrollbarWidth: "thin",
          paddingBottom: 10,
        }}
      >
        <Link
          activeClass="active"
          to="overview-tab"
          spy={true}
          offset={-170}
          // onSetActive={handleSetActive}
          className="package-details-tab"
        >
          Overview
        </Link>
        <Link
          activeClass="active"
          to="TourDates-tab"
          spy={true}
          offset={-170}
          // onSetActive={handleSetActive}
          className="package-details-tab"
        >
          Tour Dates
        </Link>
        <Link
          activeClass="active"
          to="itinerary-tab"
          spy={true}
          offset={-170}
          // onSetActive={handleSetActive}
          className="package-details-tab"
        >
          Itinerary
        </Link>
        <Link
          activeClass="active"
          to="Inclusions-Exclusions-tab"
          spy={true}
          offset={-170}
          // onSetActive={handleSetActive}
          className="package-details-tab"
        >
          Inclusions / Exclusions
        </Link>
        <Link
          activeClass="active"
          to="Tour-Information-tab"
          spy={true}
          offset={-170}
          // onSetActive={handleSetActive}
          className="package-details-tab"
        >
          Tour Information
        </Link>
        <Link
          activeClass="active"
          to="Cancellation-Policy-tab"
          spy={true}
          offset={-170}
          // onSetActive={handleSetActive}
          className="package-details-tab"
        >
          Cancellation Policy
        </Link>
        <Link
          activeClass="active"
          to="Documents-tab"
          spy={true}
          offset={-170}
          // onSetActive={handleSetActive}
          className="package-details-tab"
        >
          Documents
        </Link>
      </div>
    </div>
  );
};

export default MainPackageTab;
