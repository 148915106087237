import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import WhatsAppIcon from "../components/common/WhatsAppIcon";
import InquiryButton from "../components/common/InquiryButton";
import MobileWhatsappandInquiryBtn from "../components/common/MobileWhatsappandInquiryBtn";
import InquiryModal from "../components/InquiryModal";
import WhatsappandInquiryBtn from "../components/common/WhatsappandInquiryBtn";
import HomeImagePopupDialog from "../pages/home/HomeImagePopupDialog";
import { useSelector } from "react-redux";

const Layout = () => {
  const eventShow = useSelector((state) => state.dialogSlice.open);
  return (
    <>
      <InquiryModal />
      <Header />
      <Outlet />
      <Footer />
      {/* <InquiryButton />
      <WhatsAppIcon /> */}
      <WhatsappandInquiryBtn />
      <MobileWhatsappandInquiryBtn />
      {eventShow &&
       <HomeImagePopupDialog />
       }
    </>
  );
};

export default Layout;
