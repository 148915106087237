import React, { useEffect } from "react";
import HomeBannerSlider from "./HomeBannerSlider";
import TopIndiandestinations from "./TopIndiandestinations";
import PickupTrailIndia from "./PickupTrailIndia";
import FestivalSeason from "./FestivalSeason";
import TopInternationalDestination from "./TopInternationalDestination";
import BlogSection from "./BlogSection";
import CinemaSection from "./CinemaSection";
import InquirySection from "./InquirySection";
import TestimonialSection from "./TestimonialSection";
import { useDispatch, useSelector } from "react-redux";
import {
  getCitiesByDestId,
  getDestinationsByRegId,
  getHomeData,
  openDialog,
} from "./homeSlice";
// import { openDialog as openImageDialog } from "../../components/dialog/dialogSlice";
import { Helmet } from "react-helmet";
import InquiryModal from "../../components/InquiryModal";
import ExploreIndia from "./exploreindia/ExploreIndia";

const Home = () => {
  const dispatch = useDispatch();
  const HomeData = useSelector((state) => state.home.HomeData);
  const SEO_DATA = HomeData?.SEOTag;
  useEffect(() => {
    const isInquiryClosed = localStorage.getItem("isInquiryClosed");
    dispatch(getHomeData());
    dispatch(getCitiesByDestId({ DestinationID: 1 }));
    dispatch(getDestinationsByRegId({ RegionID: 1 }));
    setTimeout(() => {
      if (!isInquiryClosed) {
        dispatch(openDialog());
        localStorage.setItem("isInquiryClosed", true);
      }
    }, 5000);
    // For Image Pop up
    // setTimeout(() => {
    //   dispatch(openImageDialog());
    // }, 3000);
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>{SEO_DATA?.MetaTitle}</title>
        <meta name="description" content={SEO_DATA?.MetaDescription} />
        <meta name="keywords" content={SEO_DATA?.MetaKeywords} />
        <link rel="canonical" href={SEO_DATA?.Canonical} />
      </Helmet>
      <HomeBannerSlider />
      <TopIndiandestinations />
      <PickupTrailIndia />
      <ExploreIndia />
      <FestivalSeason />
      <TopInternationalDestination />
      <BlogSection />
      {/* <CinemaSection /> */}
      <InquirySection />
      <TestimonialSection />
    </>
  );
};

export default Home;
