import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSpecialityTours,
  getAllTours,
  setEventID,
  setTourListFilter,
} from "./packageListSlice";
import PackageListToolBar from "./PackageListToolBar";
import PackageListFilter from "./PackageListFilter";
import PackagesList from "./PackagesList";
import { useFormik } from "formik";
import { useLocation, useParams } from "react-router-dom";
import _debounce from "lodash/debounce";
import { Helmet } from "react-helmet";
import { getOrignalURL } from "../../common/helpers";
import PageTitle from "../../components/PageTitle";
import PackageContentSeo from "./PackageContentSeo";
import { openDialog } from "../home/homeSlice";

const PackageListPage = () => {
  const dispatch = useDispatch();
  const { MainRoute, SectorURL, Customize } = useParams();
  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;

  const tourPackageType = location.state?.tourPackageType;

  const { SeoPoints, TourListBanner } = useSelector(
    (state) => state.packageList.TourListData
  );

  const TourListFilter = useSelector(
    (state) => state.packageList.TourListFilter
  );

  const formik = useFormik({
    initialValues: TourListFilter,
    onSubmit: () => {},
  });

  useEffect(() => {
    dispatch(
      setTourListFilter({
        ...TourListFilter,
        Rate: formik.values?.Rate,
        NoOfDays: formik.values?.NoOfDays,
        SpecialityType: formik.values?.SpecialityType,
        OrderBy: formik.values?.OrderBy,
        TourType: formik.values?.TourType,
        SectorURL: getOrignalURL(SectorURL),
      })
    );
  }, [formik.values, location.pathname]);

  const delayedApiCall = _debounce((filter) => {
    const fetchTours = async () => {
      if (SectorURL === "couple-tour") {
        const res = await dispatch(getAllSpecialityTours(filter));
        console.log("res", res);
        if (res?.payload === undefined || res?.payload?.TourList?.length < 1) {
          dispatch(openDialog());
        }
      } else {
        const res = await dispatch(getAllTours(filter));
        if (res?.payload === undefined || res?.payload?.TourList?.length < 1) {
          dispatch(openDialog());
        }
      }
    };
    fetchTours();
  }, 500);

  useEffect(() => {
    const Rate = TourListFilter?.Rate;
    if (SectorURL === "couple-tour") {
      const specialityFilter = {
        ...TourListFilter,
        Rate: `Rate BETWEEN ${Rate[0]} AND ${Rate[1]}`,
        SectorURL: "",
        SpecialityURL: SectorURL,
        OrderBy:
          typeof TourListFilter.OrderBy === "string"
            ? TourListFilter.OrderBy
            : TourListFilter.OrderBy.value,
        NoOfDays: TourListFilter.NoOfDays.join(" OR "),
        TourType: TourListFilter.TourType.join(","),
        SpecialityType: TourListFilter.SpecialityType.join(","),
      };
      delayedApiCall(specialityFilter);
    }
    // else if (tourPackageType === "City") {
    //   const cityFilter = {
    //     ...TourListFilter,
    //     Rate: `Rate BETWEEN ${Rate[0]} AND ${Rate[1]}`,
    //     SectorURL: "",
    //     CityURL: getOrignalURL(SectorURL),
    //     OrderBy:
    //       typeof TourListFilter.OrderBy === "string"
    //         ? TourListFilter.OrderBy
    //         : TourListFilter.OrderBy.value,
    //     NoOfDays: TourListFilter.NoOfDays.join(" OR "),
    //     TourType: TourListFilter.TourType.join(","),
    //     SpecialityType: TourListFilter.SpecialityType.join(","),
    //   };
    //   delayedApiCall(cityFilter);
    // }
    else {
      const filter = {
        ...TourListFilter,
        Rate: `Rate BETWEEN ${Rate[0]} AND ${Rate[1]}`,
        SectorURL: getOrignalURL(SectorURL),
        OrderBy:
          typeof TourListFilter.OrderBy === "string"
            ? TourListFilter.OrderBy
            : TourListFilter.OrderBy.value,
        NoOfDays: TourListFilter.NoOfDays.join(" OR "),
        TourType: TourListFilter.TourType.join(","),
        SpecialityType: TourListFilter.SpecialityType.join(","),
      };
      delayedApiCall(filter);
    }

    return () => delayedApiCall.cancel();
  }, [TourListFilter]);

  // if (!loading && !TourList) {
  //   return <NoDataFound />;
  // }

  return (
    <>
      <Helmet>
        <title>{SeoPoints?.MetaTitle}</title>
        <meta name="description" content={SeoPoints?.MetaDescription} />
        <meta name="keywords" content={SeoPoints?.MetaKeywords} />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <PageTitle BannerTitle={"Tour Package"} BannerImage={TourListBanner} />
      <PackageContentSeo />
      <div className="tour-info">
        <div className="container container-1480">
          <form onSubmit={formik.handleSubmit}>
            <PackageListToolBar formik={formik} />
            <div className="row">
              <PackageListFilter formik={formik} />
              <PackagesList formik={formik} />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PackageListPage;
