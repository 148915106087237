import React from "react";
import PackageCard from "./PackageCard";
import PackageListPagination from "./PackageListPagination";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import NoDataFound from "../../components/common/NoDataFound";

const PackagesList = () => {
  const TourListData = useSelector((state) => state.packageList.TourListData);
  const loading = useSelector((state) => state.packageList.loading);
  const tourLoading = useSelector((state) => state.packageList.tourLoading);

  if (loading || tourLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="col-sm-12 col-md-12 col-lg-8 col-xl-9 right-filters-col">
      {!loading && !tourLoading && !TourListData?.TourList ? (
        <NoDataFound />
      ) : (
        <>
          {TourListData?.TourList?.map((tour) => (
            <PackageCard tour={tour} />
          ))}
          <PackageListPagination
            pageCount={
              (TourListData?.TourList &&
                TourListData?.TourList[0]?.TotalCountData) ||
              0
            }
          />
        </>
      )}
    </div>
  );
};

export default PackagesList;
