import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getOrignalURL } from "../../common/helpers";

const PackageContentSeo = () => {
  const { PackageContentData } = useSelector(
    (state) => state.packageList.TourListData
  );
  const { SectorURL } = useParams();

  const [isFullContent, setIsFullContent] = useState(false);

  return (
    <div className="tour-list-detail">
      <div className="container container-1480">
        {(PackageContentData?.ShortDescription ||
          PackageContentData?.LongDescrtiption) && (
          <div className="row">
            <div className="col-lg-4 col-md-12">
              <h2>{SectorURL?.split("-")?.join(" ")?.toUpperCase()} </h2>
              <p
                dangerouslySetInnerHTML={{
                  __html: PackageContentData?.ShortDescription,
                }}
              />
              {/* {PackageContentData?.ShortDescription}</p> */}
            </div>
            <div className="col-lg-8 col-md-12">
              <div
                className={`kashmir-list-detail ${
                  isFullContent ? "active" : ""
                } `}
              >
                <div
                  className="kashmir-list-detail-body"
                  dangerouslySetInnerHTML={{
                    __html: PackageContentData?.LongDescrtiption,
                  }}
                />
                {PackageContentData?.LongDescrtiption && (
                  <div className="data-read-more">
                    <a
                      className="read-more-action action-more cursor-pointer"
                      onClick={() => setIsFullContent(true)}
                    >
                      {" "}
                      Read More
                    </a>
                    <a
                      className="read-more-action action-less cursor-pointer"
                      onClick={() => setIsFullContent(false)}
                    >
                      {" "}
                      Read Less
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PackageContentSeo;
