import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainHeader from "./MainHeader";
import { v4 as uuidv4 } from "uuid";
import SimilarPackages from "./SimilarPackages";
import PackageContent from "./PackageContent";
import PackageForm from "./PackageForm";
import RecViewedPackages from "./RecViewedPackages";
import { useLocation, useParams } from "react-router-dom";
import { getTourDetails } from "./packageDetailSlice";
import { Helmet } from "react-helmet";
import MainPackageTab from "./packageTab/MainPackageTab";
import BredCrum from "../../components/BredCrum";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import NoDataFound from "../../components/common/NoDataFound";
import { getSessionId, setSessionId } from "../../common/helpers";
import OverView from "./packageTab/innerContents/OverView";
import Itinerary from "./packageTab/innerContents/Itinerary";
import TourInformation from "./packageTab/innerContents/TourInformation";
import InclusionExclusion from "./packageTab/innerContents/InclusionExclusion";
import CancellationPolicy from "./packageTab/innerContents/CancellationPolicy";
import TourDates from "./packageTab/innerContents/TourDates";
import TourDocuments from "./packageTab/innerContents/TourDocuments";

const PackageDetailPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;
  const { TourURL } = useParams();

  const tourData = location.state?.tourData;

  const TourDetails = useSelector((state) => state.packageDetail.TourDetails);
  const loading = useSelector((state) => state.packageDetail.loading);

  useEffect(() => {
    const sessionId = getSessionId();
    setSessionId(sessionId);
    const payload = {
      TourID: tourData?.ID,
      RateType: tourData?.Ratetype,
      NoOfNights: tourData?.NoOfNights?.toString(),
      RoomTypeID: tourData?.RoomTypeID,
      TourURL: TourURL,
      SessionID: sessionId,
      CustomerID: "0",
    };
    dispatch(getTourDetails(payload));
  }, [dispatch, location.pathname, tourData]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>{TourDetails?.MetaTitle}</title>
        <meta name="description" content={TourDetails?.MetaDescription} />
        <meta name="keywords" content={TourDetails?.MetaKeywords} />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <BredCrum />
      <div className="tour-detail-main">
        {!TourDetails?.TourName && !loading ? (
          <NoDataFound />
        ) : (
          <div className="container container-1480">
            <MainHeader />
            <div className="row">
              <div className="col-sm-12 packages-left-col">
                <PackageContent />
              </div>
              <div className="col-sm-12 packages-side-col">
                <PackageForm />
                {/* <RecViewedPackages /> */}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <MainPackageTab />
                <OverView />
                <TourDates />
                <Itinerary />
                <InclusionExclusion />
                <TourInformation />
                <CancellationPolicy />
                <TourDocuments />
              </div>
            </div>
            <SimilarPackages />
          </div>
        )}
      </div>
    </>
  );
};

export default PackageDetailPage;
