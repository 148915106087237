import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MAP_IMAGE from "../../../../assets/images/maps.png";
import { tourItineraryDownload } from "../../packageDetailSlice";
import { useLocation, useParams } from "react-router-dom";
import { getSessionId, setSessionId } from "../../../../common/helpers";
import { API_APP_URL } from "../../../../app/config";
import { axiosApiInstance } from "../../../../app/axios";
import { ErrorToast } from "../../../../components/common/Toast";
import { Element } from "react-scroll";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
} from "react-icons/io";

const Itinerary = () => {
  const TourDetails = useSelector((state) => state.packageDetail.TourDetails);
  const location = useLocation();
  const { TourURL } = useParams();
  const tourData = location.state?.tourData;

  const [itineraryLoading, setItineraryLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleDownloadItinerary = async () => {
    const sessionId = getSessionId();
    const payload = {
      TourID: tourData?.ID,
      RateType: tourData?.Ratetype,
      NoOfNights: tourData?.NoOfNights?.toString(),
      RoomTypeID: tourData?.RoomTypeID,
      TourURL: TourURL,
      SessionID: sessionId,
      CustomerID: "0",
    };

    try {
      setItineraryLoading(true);
      const response = await axiosApiInstance.post(
        `${API_APP_URL}/Tour/TourItineraryDownload`,
        payload
      );
      const ItineraryUrl = response.data?.Data[0]?.PdfPath;
      if (ItineraryUrl) {
        window.open(ItineraryUrl, "_blank");
      } else {
        ErrorToast("No Itinerary Found!");
      }
      setItineraryLoading(false);
    } catch (error) {
      setItineraryLoading(false);
      ErrorToast(error?.message || "Something went Wrong!");
      console.log("error: " + error?.message);
    }
  };

  return (
    <Element
      className="tab-pane fade show active"
      id="Itinerary"
      name="itinerary-tab"
      role="tabpanel"
      aria-labelledby="Itinerary-tab"
      style={{
        textAlign: "justify",
        padding: 15,
        boxShadow: "0 0 10px lightgray",
        borderRadius: 20,
        marginBottom: 30,
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
          background: "antiquewhite",
          padding: 10,
          borderRadius: 10,
        }}
      >
        <h6 style={{ alignSelf: "center", marginBottom: 0, textAlign: "left" }}>
          DAY WISE ITINERARY
        </h6>
        <button
          disabled={itineraryLoading}
          onClick={handleDownloadItinerary}
          className="btn btn-fill"
        >
          Download
        </button>
      </div>
      <div
        style={{
          height: !expanded ? "500px" : "fit-content",
          overflow: !expanded ? "hidden" : "none",
          marginBottom: !expanded ? 0 : 70,
        }}
      >
        {TourDetails?.touritinerary?.map((itinerary, index, array) => (
          <div
            style={{
              borderBottom:
                index === array.length - 1 ? "none" : "1px solid #F26A2F",
            }}
          >
            <div style={{ fontWeight: 900, margin: "25px 0 5px" }}>
              <span style={{ color: "#F26A2F" }}>
                Day {itinerary?.ItineraryDay}&nbsp;:
              </span>{" "}
              &nbsp;
              {itinerary?.ItineraryTitle}
            </div>
            <div
              style={{ textAlign: "justify" }}
              dangerouslySetInnerHTML={{
                __html: itinerary?.ItineraryDescription || "",
              }}
            ></div>
            <div className="stay-tb">
              <ul>
                {itinerary?.tourfcs?.map((fcs) => (
                  <li>
                    <img src={fcs?.Image} /> {fcs?.Name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          textAlign: "center",
          marginTop: 10,
          // boxShadow: "0px -38px 11px #F9F9F9",
          position: "absolute",
          bottom: 12,
          left: 0,
          right: 0,
          background: "linear-gradient(180deg, transparent, #F9F9F9)",
        }}
      >
        <button
          onClick={toggleExpand}
          className="btn btn-fill"
          style={{ marginTop: 91 }}
        >
          {expanded ? "Read Less" : "Expand Full Itinerary"}{" "}
          {expanded ? <IoIosArrowDropupCircle /> : <IoIosArrowDropdownCircle />}
        </button>
      </div>
      {/* <div className="accordion" id="accordionExample">
        {TourDetails?.touritinerary?.map((itinerary, index) => (
          <div className="accordion-item" key={itinerary?.ItineraryDay}>
            <h2
              className="accordion-header"
              id={"heading" + itinerary?.ItineraryDay}
            >
              <button
                className={`accordion-button ${index === 0 ? "" : "collapsed"}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={"#collapse" + itinerary?.ItineraryDay}
                aria-expanded={index === 0 ? "true" : "false"}
                aria-controls={"collapse" + itinerary?.ItineraryDay}
              >
                <div className="days-left">
                  <span>
                    <strong>Day {itinerary?.ItineraryDay}</strong>
                  </span>
                </div>
                <h6>
                  <img src={MAP_IMAGE} />
                  {itinerary?.ItineraryTitle}
                </h6>
              </button>
            </h2>
            <div
              id={"collapse" + itinerary?.ItineraryDay}
              className={`accordion-collapse collapse ${
                index === 0 ? "show" : ""
              }`}
              aria-labelledby={"heading" + itinerary?.ItineraryDay}
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <div
                  dangerouslySetInnerHTML={{
                    __html: itinerary?.ItineraryDescription || "",
                  }}
                ></div>
                <div className="stay-tb">
                  <ul>
                    {itinerary?.tourfcs?.map((fcs) => (
                      <li>
                        <img src={fcs?.Image} /> {fcs?.Name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div> */}
    </Element>
    // <div
    //   className="tab-pane fade show active"
    //   id="Itinerary"
    //   role="tabpanel"
    //   aria-labelledby="Itinerary-tab"
    // >
    //   <div
    //     style={{
    //       display: "flex",
    //       justifyContent: "space-between",
    //       marginBottom: 10,
    //     }}
    //   >
    //     <h6 style={{ alignSelf: "center", marginBottom: 0 }}>ITINERARY</h6>
    //     <button
    //       disabled={itineraryLoading}
    //       onClick={handleDownloadItinerary}
    //       className="btn btn-fill"
    //     >
    //       Download
    //     </button>
    //   </div>
    //   <div className="accordion" id="accordionExample">
    //     {TourDetails?.touritinerary?.map((itinerary, index) => (
    //       <div className="accordion-item" key={itinerary?.ItineraryDay}>
    //         <h2
    //           className="accordion-header"
    //           id={"heading" + itinerary?.ItineraryDay}
    //         >
    //           <button
    //             className={`accordion-button ${index === 0 ? "" : "collapsed"}`}
    //             type="button"
    //             data-bs-toggle="collapse"
    //             data-bs-target={"#collapse" + itinerary?.ItineraryDay}
    //             aria-expanded={index === 0 ? "true" : "false"}
    //             aria-controls={"collapse" + itinerary?.ItineraryDay}
    //           >
    //             <div className="days-left">
    //               <span>
    //                 <strong>Day {itinerary?.ItineraryDay}</strong>
    //               </span>
    //             </div>
    //             <h6>
    //               <img src={MAP_IMAGE} />
    //               {itinerary?.ItineraryTitle}
    //             </h6>
    //           </button>
    //         </h2>
    //         <div
    //           id={"collapse" + itinerary?.ItineraryDay}
    //           className={`accordion-collapse collapse ${
    //             index === 0 ? "show" : ""
    //           }`}
    //           aria-labelledby={"heading" + itinerary?.ItineraryDay}
    //           data-bs-parent="#accordionExample"
    //         >
    //           <div className="accordion-body">
    //             <div
    //               dangerouslySetInnerHTML={{
    //                 __html: itinerary?.ItineraryDescription || "",
    //               }}
    //             ></div>
    //             <div className="stay-tb">
    //               <ul>
    //                 {itinerary?.tourfcs?.map((fcs) => (
    //                   <li>
    //                     <img src={fcs?.Image} /> {fcs?.Name}
    //                   </li>
    //                 ))}
    //               </ul>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     ))}
    //   </div>
    // </div>
  );
};

export default Itinerary;
