import React, { useEffect, useState } from "react";
import I_TATA from "../assets/images/iata-small.png";
import GUJTRSM from "../assets/images/gujarattourism-small.png";
import LIC from "../assets/images/lic-small.png";
import IATO from "../assets/images/iato-small.png";
import TAG from "../assets/images/tag-small.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  closeDialog,
  getAllDestinations,
  sendInquiry,
  sendInquiryToNeoDoveSoftware,
} from "../pages/home/homeSlice";
// import LoadingSpinner from "./common/LoadingSpinner";
import { useNavigate } from "react-router-dom";

const InquiryModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inquiryLoading = useSelector((state) => state.home.inquiryLoading);
  // const destLoading = useSelector((state) => state.home.destLoading);
  const DestinationList = useSelector((state) => state.home.DestinationList);
  const modalOpen = useSelector((state) => state.home.modalOpen);
  const [DestList, setDestList] = useState(DestinationList || []);

  useEffect(() => {
    dispatch(getAllDestinations());
  }, []);

  const handleCloseInquiryModal = () => {
    dispatch(closeDialog());
  };

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Full Name is required"),
    SectorType: Yup.string().required("Sector Type is required"),
    SectorID: Yup.string().required("Sector is required"),
    EmailID: Yup.string()
      .email("Invalid email address")
      .required("Email Address is required"),
    MobileNo: Yup.number().required("Mobile Number is required"),
  });

  const formik = useFormik({
    initialValues: {
      Name: "",
      EmailID: "",
      MobileNo: "",
      SectorType: "",
      SectorID: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const payload = {
        ...values,
        MobileNo: values?.MobileNo?.toString(),
        SectorID: values?.SectorID ? +values.SectorID : 0,
      };

      const neodovePayload = {
        name: values?.Name,
        mobile: values?.MobileNo,
        email: values?.EmailID,
        detail: values?.SectorType,
        detail2: "",
      };
      try {
        await dispatch(sendInquiry(payload)).unwrap();
        await dispatch(sendInquiryToNeoDoveSoftware(neodovePayload)).unwrap();
        resetForm();
        handleCloseInquiryModal();
        navigate("/thankyou/");
      } catch (error) {
        console.error("API error:", error);
      }
    },
  });

  const handleSectorTypeChange = (e) => {
    const selectedSectorType = e.target.value;
    formik.handleChange(e);

    const filteredDestList =
      selectedSectorType === "DOMESTIC"
        ? DestinationList?.filter((dest) => dest.SectorType === "DOMESTIC")
        : DestinationList?.filter((dest) => dest.SectorType !== "DOMESTIC") ||
          [];

    formik.setFieldValue("SectorID", "");
    setDestList(filteredDestList);
  };

  //   if (destLoading) {
  //     return <LoadingSpinner />;
  //   }

  return (
    <>
      <div
        className={`modal fade ${modalOpen ? "show" : ""}`}
        id="onload"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        style={{ display: modalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-header">
                <h5 className="modal-title">Quick Enquiry</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => handleCloseInquiryModal()}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="modal_enquiry_form">
                  <div className="row">
                    <div className="col-md-6 col-sm-12">
                      <div className="modal-left">
                        <h3>Why AJAY MODI TRAVELS?</h3>
                        <ul>
                          <li>
                            <p>
                              <span>1</span>92% Customer Satisfaction
                            </p>
                          </li>
                          <li>
                            <p>
                              <span>2</span>No Over Commitments
                            </p>
                          </li>
                          <li>
                            <p>
                              <span>3</span>Value For Money
                            </p>
                          </li>
                          <li>
                            <p>
                              <span>4</span>Pure Veg. Food in all Domestic Tours
                            </p>
                          </li>
                          <li>
                            <p>
                              <span>5</span>No Hidden Cost
                            </p>
                          </li>
                          <li>
                            <p>
                              <span>6</span>LTC Approved Agent By Govt of
                              Gujarat & LIC
                            </p>
                          </li>
                        </ul>
                        <div className="brand-box">
                          <a className="cursor-pointer">
                            <img src={I_TATA} alt="TATA" />
                          </a>
                          <a className="cursor-pointer">
                            <img src={TAG} alt="" />
                          </a>
                          <a className="cursor-pointer">
                            <img src={IATO} alt="" />
                          </a>
                          <a className="cursor-pointer">
                            <img src={LIC} alt="" />
                          </a>
                          <a className="cursor-pointer">
                            <img src={GUJTRSM} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="modal-form">
                        <h3>Where Do You Want To Go ?</h3>

                        <input
                          type="text"
                          placeholder="Your Full Name"
                          name="Name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.Name}
                          className={
                            formik.touched.Name && formik.errors.Name
                              ? "error-border"
                              : ""
                          }
                        />
                        <input
                          name="EmailID"
                          type="text"
                          placeholder="Email Address"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.EmailID}
                          className={
                            formik.touched.EmailID && formik.errors.EmailID
                              ? "error-border"
                              : ""
                          }
                        />
                        <input
                          type="number"
                          name="MobileNo"
                          placeholder="Mobile Number"
                          onChange={formik.handleChange}
                          onInput={(e) => {
                            e.target.value = Math.max(
                              0,
                              parseInt(e.target.value)
                            )
                              .toString()
                              .slice(0, 10);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.MobileNo}
                          className={
                            formik.touched.MobileNo && formik.errors.MobileNo
                              ? "error-border"
                              : ""
                          }
                        />
                        <select
                          name="SectorType"
                          onChange={handleSectorTypeChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.SectorType}
                          className={
                            formik.touched.SectorType &&
                            formik.errors.SectorType
                              ? "error-border"
                              : ""
                          }
                        >
                          <option value="">Select Type</option>
                          <option value="DOMESTIC">Domestic</option>
                          <option value="INTERNATIONAL">International</option>
                        </select>
                        <select
                          name="SectorID"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.SectorID}
                          disabled={!formik.values.SectorType}
                          className={
                            formik.touched.SectorID && formik.errors.SectorID
                              ? "error-border"
                              : ""
                          }
                        >
                          <option value="">Select Destination</option>
                          {DestList?.map((option) => {
                            return (
                              <option key={option?.ID} value={option?.ID}>
                                {option?.SectorName}
                              </option>
                            );
                          })}
                        </select>

                        <div className="modal-call">
                          <span>
                            Call us For Details:{" "}
                            <a href="tel:+ +91-98250 96999"> +91-98250 96999</a>
                          </span>
                          <span>
                            Email :{" "}
                            <a href="mailto:info@ajaymodi.com">
                              info@ajaymodi.com
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  disabled={inquiryLoading}
                  className="btn btn-primary"
                >
                  {inquiryLoading ? "Submiting..." : "Send Inquiry"}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => handleCloseInquiryModal()}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        style={{ display: modalOpen ? "block" : "none" }}
        className={`modal-backdrop fade ${modalOpen ? "show" : ""}`}
      ></div>
    </>
  );
};

export default InquiryModal;
