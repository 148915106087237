import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { sendInquiry, sendInquiryToNeoDoveSoftware } from "../home/homeSlice";
import { useNavigate, useParams } from "react-router-dom";

const PackageForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { TourURL } = useParams();
  const inquiryLoading = useSelector((state) => state.home.inquiryLoading);
  const TourDetails = useSelector((state) => state.packageDetail.TourDetails);

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Full Name is required"),
    EmailID: Yup.string()
      .email("Invalid email address")
      .required("Email Address is required"),
    MobileNo: Yup.number().required("Mobile Number is required"),
  });

  const formik = useFormik({
    initialValues: {
      Name: "",
      EmailID: "",
      MobileNo: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const payload = {
        ...values,
        TourURL: TourURL,
        MobileNo: values?.MobileNo?.toString(),
      };

      const neodovePayload = {
        name: values?.Name,
        mobile: values?.MobileNo,
        email: values?.EmailID,
        detail: values?.TourURL,
        detail2: "",
      };

      try {
        await dispatch(sendInquiry(payload)).unwrap();
        await dispatch(sendInquiryToNeoDoveSoftware(neodovePayload)).unwrap();
        resetForm();
        navigate("/thankyou/");
      } catch (error) {
        console.error("API error:", error);
      }
    },
  });

  return (
    <div className="packages-sidebar-box">
      <div className="packages-sidebar-box-title">
        <h5>Where do you want to go?</h5>
      </div>
      <div className="packages-sidebar-box-data nopadding">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-data">
            <div className="packages-fromfield">
              <label>Name</label>
              <input
                type="text"
                placeholder="Your Full Name"
                name="Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.Name}
                className={
                  formik.touched.Name && formik.errors.Name
                    ? "error-border"
                    : ""
                }
              />
            </div>
            <div className="packages-fromfield">
              <label>Email Address</label>
              <input
                name="EmailID"
                type="text"
                placeholder="Email Address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.EmailID}
                className={
                  formik.touched.EmailID && formik.errors.EmailID
                    ? "error-border"
                    : ""
                }
              />
            </div>
            <div className="packages-fromfield">
              <label>Phone No</label>
              <input
                type="number"
                name="MobileNo"
                placeholder="Mobile Number"
                onChange={formik.handleChange}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.MobileNo}
                className={
                  formik.touched.MobileNo && formik.errors.MobileNo
                    ? "error-border"
                    : ""
                }
              />
            </div>
          </div>
          <div className="super-deal-form">
            <span className="pricestaring">
              SUPER DEAL PRICE <br />
              <p className="mt-2 mb-2">
                Starting From <br />
                <strong>
                  ₹
                  {TourDetails?.tourcost &&
                    TourDetails?.tourcost[0] &&
                    TourDetails?.tourcost[0]?.Rate}
                  *
                </strong>
              </p>
            </span>
            <p>
              {TourDetails?.tourcost &&
                TourDetails?.tourcost[0] &&
                TourDetails?.tourcost[0]?.RateType}
            </p>
          </div>
          <div className="enwureyform">
            <button type="submit" className="btn btn-fill w-100">
              {inquiryLoading ? "Submiting..." : "Enquire Now"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PackageForm;
