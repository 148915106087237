import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosApiInstance, axiosApiSecureInstance } from "../../app/axios";
import { API_APP_URL } from "../../app/config";

export const getAllTours = createAsyncThunk("Tour/TourList", async (filter) => {
  const response = await axiosApiInstance.post(
    `${API_APP_URL}/Tour/TourList`,
    filter
  );
  const TourList = response.data?.Data || [];
  const SeoPoints = {
    MetaTitle: response.data?.MetaTitle || "",
    MetaDescription: response.data?.MetaDescription || "",
    Canonical: response.data?.Canonical || "",
    MetaKeywords: response.data?.MetaKeywords || "",
  };
  const TourListBanner = response.data?.BannerImage || "";
  const PackageContentData = {
    ShortDescription: response.data?.ShortDescription || "",
    LongDescrtiption: response.data?.LongDescrtiption || "",
  };

  return { TourList, SeoPoints, TourListBanner, PackageContentData };
});

export const getAllSpecialityTours = createAsyncThunk(
  "Tour/SpecialityTourList",
  async (filter) => {
    const response = await axiosApiInstance.post(
      `${API_APP_URL}/Tour/SpecialityTourList `,
      filter
    );
    const TourList = response.data?.Data || [];
    const SeoPoints = {
      MetaTitle: response.data?.MetaTitle || "",
      MetaDescription: response.data?.MetaDescription || "",
      Canonical: response.data?.Canonical || "",
      MetaKeywords: response.data?.MetaKeywords || "",
    };
    const TourListBanner = response.data?.BannerImage || "";
    const PackageContentData = {
      ShortDescription: response.data?.ShortDescription || "",
      LongDescrtiption: response.data?.LongDescrtiption || "",
    };

    return { TourList, SeoPoints, TourListBanner, PackageContentData };
  }
);

export const getAllTourTypes = createAsyncThunk("Tour/TourTypes", async () => {
  const response = await axiosApiInstance.post(
    `${API_APP_URL}/RoomType/FindAllRoomType`,
    {
      RoomType: "",
    }
  );
  return response.data?.Data?.map((item) => {
    return {
      ID: item?.ID,
      Title: item?.RoomType,
      Prefix: item?.Prefix,
      RoomTypeURL: item?.RoomTypeURL,
    };
  });
});

export const getAllSpecialityTypes = createAsyncThunk(
  "SpecialityType/FindAllSpecialityType",
  async () => {
    const response = await axiosApiInstance.post(
      `${API_APP_URL}/SpecialityType/FindAllSpecialityType`,
      {
        SpecialityType: "",
      }
    );
    return response.data?.Data?.map((item) => {
      return {
        ID: item?.ID,
        Title: item?.SpecialityType,
        Prefix: item?.Prefix,
        SpecialityURL: item?.SpecialityURL,
      };
    });
  }
);

const initialState = {
  loading: false,
  tourLoading: false,
  error: "",
  TourListData: {
    TourList: [],
    SeoPoints: {},
  },
  TourTypes: [],
  SpecialityTypes: [],
  TourListFilter: {
    RegionID: "",
    IsHimalayanTreks: "",
    Rate: [0, 200000],
    NoOfDays: [],
    SpecialityType: [],
    OrderBy: "ORDER BY RATE ASC",
    SectorName: "",
    TourType: [],
    SectorURL: "",
    PageIndex: 1,
    PageSize: 30,
  },
};

const packageListSlice = createSlice({
  name: "packageListSlice",
  initialState,
  reducers: {
    setTourListFilter: (state, action) => {
      state.TourListFilter = action.payload;
    },
    clearTourListFilter: (state) => {
      state.TourListFilter = initialState.TourListFilter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTours.pending, (state) => {
      state.tourLoading = true;
    });
    builder.addCase(getAllTours.fulfilled, (state, action) => {
      state.tourLoading = false;
      state.TourListData = action.payload;
      state.error = "";
    });
    builder.addCase(getAllTours.rejected, (state, action) => {
      state.tourLoading = false;
      state.TourListData = [];
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllSpecialityTours.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllSpecialityTours.fulfilled, (state, action) => {
      state.loading = false;
      state.TourListData = action.payload;
      state.error = "";
    });
    builder.addCase(getAllSpecialityTours.rejected, (state, action) => {
      state.loading = false;
      state.TourListData = [];
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllTourTypes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllTourTypes.fulfilled, (state, action) => {
      state.loading = false;
      state.TourTypes = action.payload;
      state.error = "";
    });
    builder.addCase(getAllTourTypes.rejected, (state, action) => {
      state.loading = false;
      state.TourTypes = [];
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllSpecialityTypes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllSpecialityTypes.fulfilled, (state, action) => {
      state.loading = false;
      state.SpecialityTypes = action.payload;
      state.error = "";
    });
    builder.addCase(getAllSpecialityTypes.rejected, (state, action) => {
      state.loading = false;
      state.SpecialityTypes = [];
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { setTourListFilter, clearTourListFilter } =
  packageListSlice.actions;
export default packageListSlice.reducer;
