import React, { useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import { useSelector } from "react-redux";
import BACK_IMAGE from "../../assets/images/discouver-bg.jpg";
import { useNavigate } from "react-router-dom";
import {
  INDIAN_PACKAGE_PATH_KEY,
  INTERNATIONAL_PACKAGE_PATH_KEY,
} from "../../common/costants";
import Slider from "react-slick";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const TopIndiandestinations = () => {
  const HomeData = useSelector((state) => state.home.HomeData);
  const navigate = useNavigate();

  const handleNavigate = (dest) => {
    if (dest?.DestinationURL) {
      if (dest?.DestinationURL === "goa") {
        navigate("weekend-gateways/goa-tour-packages/");
      } else {
        navigate(
          `/${
            dest?.SectorType === "INTERNATIONAL"
              ? INTERNATIONAL_PACKAGE_PATH_KEY
              : INDIAN_PACKAGE_PATH_KEY
          }/${dest?.DestinationURL}-tour-packages/`
        );
      }
    }
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const settings = {
    className: "discover-slick",
    dots: false,
    infinite: true,
    autoplay: true,
    centerMode: true,
    centerPadding: "0px",
    autoplaySpeed: 4000,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="discouver-section full-section relative">
      <div className="bg-sections">
        <img src={BACK_IMAGE} alt="background" />
      </div>
      <div className="under-construction-message">
        <h5>Website is under Maintenance!</h5>
      </div>
      <div className="container relative">
        <h2>Discover Top Indian Destinations</h2>
        <div></div>
        <Slider {...settings}>
          {HomeData?.DestinationList?.map((dest) => {
            return (
              <div className="item">
                <div className="discover-pic">
                  <img
                    src={dest?.DestinationImage}
                    onClick={() => handleNavigate(dest)}
                  />
                </div>
                <h4>{dest?.SectorName}</h4>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default TopIndiandestinations;
