import React from "react";
import BlogListCard from "./BlogListCard";
import { useSelector } from "react-redux";
import NoDataFound from "../../components/common/NoDataFound";
import LoadingSpinner from "../../components/common/LoadingSpinner";

const BlogList = () => {
  const BlogListData = useSelector((state) => state.blogList.BlogListData);
  const loading = useSelector((state) => state.blogList.loading);

  console.log("BlogListData", BlogListData);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="col-sm-12 col-md-8">
      {!loading && !BlogListData ? (
        <NoDataFound />
      ) : (
        <>
          {BlogListData &&
            BlogListData?.length > 0 &&
            BlogListData?.map((blog) => <BlogListCard blog={blog} />)}
        </>
      )}
    </div>
  );
};

export default BlogList;
