import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const WeekendGateways = () => {
  const STAR_TABS = ["3 Star", "4 Star", "5 Star"];
  const DEST_TABS = ["Goa", "Rajasthan"];
  const [activeStarTab, setStarActiveTab] = useState(STAR_TABS[0]);
  const [activeDestTab, setDestActiveTab] = useState(DEST_TABS[0]);
  const navigate = useNavigate();

  const { CHolidaysData } = useSelector(
    (state) => state.customizeHolidays.CustomizeHolidaysData
  );

  const handleNavigate = (pckg) => {
    if (pckg?.PageURL && pckg?.Destination) {
      navigate(
        `/weekend-gateways/${pckg?.Destination?.toLowerCase()}-tour-packages/${
          pckg?.PageURL
        }/`
      );
    }
  };

  const handleStarTabClick = (tab) => {
    setStarActiveTab(tab);
  };

  const handleDestTabClick = (tab) => {
    setDestActiveTab(tab);
  };

  const getActiveStarTab = (tab) => {
    const starNumber = tab.split(" ")[0];
    const spelledOutNumber =
      Number(starNumber) === 3
        ? "Three"
        : Number(starNumber) === 4
        ? "Four"
        : Number(starNumber) === 5
        ? "Five"
        : "";
    return `${spelledOutNumber}Star`;
  };

  const renderPackages = () => {
    const packagesData = {
      ThreeStarGoa: CHolidaysData?.GoaList?.ThreeStarGoa || [],
      FourStarGoa: CHolidaysData?.GoaList?.FourStarGoa || [],
      FiveStarGoa: CHolidaysData?.GoaList?.FiveStarGoa || [],
      ThreeStarRajasthan: CHolidaysData?.RajasthanList?.ThreeStarRajsthan || [],
      FourStarRajasthan: CHolidaysData?.RajasthanList?.FourStarRajsthan || [],
      FiveStarRajasthan: CHolidaysData?.RajasthanList?.FiveStarRajsthan || [],
    };

    const activePackages =
      packagesData[`${getActiveStarTab(activeStarTab)}${activeDestTab}`];

    if (!activePackages) {
      return (
        <div className="col-12">
          <h6 className="text-center">No Weekend Gateways Available.</h6>
        </div>
      );
    }

    return activePackages.map((pckg, index) => (
      <div key={index} className="col-lg-4 col-md-6 col-sm-12">
        <div className="similer-packages">
          <div className="similarproduct-pic">
            <img
              className="cursor-pointer"
              onClick={() => handleNavigate(pckg)}
              src={pckg?.DestinationImage}
            />
            <div className="days-desgin">
              <span>{pckg?.NoofDays} Days</span>{" "}
              <strong>{pckg?.NoofNights} Nights</strong>
            </div>
          </div>
          <div className="similarproduct-data">
            <h6
              className="hoverable-title"
              onClick={() => handleNavigate(pckg)}
            >
              {pckg?.Name}
            </h6>
            <div className="price-similar">
              <strong>Package Starting From:</strong>
              <span>₹{pckg?.TotalPrice}*</span>
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className="cusomize-holiday-tabbing">
        <h2>Weekend Gateways</h2>
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          {DEST_TABS.map((tab, index) => (
            <li key={index} className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeDestTab === tab ? "active" : ""}`}
                onClick={() => handleDestTabClick(tab)}
              >
                {tab}
              </button>
            </li>
          ))}
        </ul>
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          {STAR_TABS.map((tab, index) => (
            <li key={index} className="nav-item" role="presentation">
              <button
                className={`nav-link ${activeStarTab === tab ? "active" : ""}`}
                onClick={() => handleStarTabClick(tab)}
              >
                {tab}
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="cusomize-holiday">
              <div className="container">
                <div className="row">{renderPackages()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WeekendGateways;
