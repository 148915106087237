import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../app/axios";
import { API_APP_URL } from "../../app/config";

export const getWeekendGatewayDetail = createAsyncThunk(
  "WeekendGateway/WeekendGatewayPageData",
  async (payload) => {
    const response = await axiosApiInstance.get(
      `${API_APP_URL}/WeekendGateway/WeekendGatewayDetails?destinationURL=${payload?.Url}`
    );
    const WeekendGatewayDetail = response.data.Data;
    return WeekendGatewayDetail;
  }
);

const initialState = {
  loading: false,
  error: "",
  WeekendGatewayDetail: {},
};

const weekendGatewayDetailSlice = createSlice({
  name: "weekendGatewayDetailSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWeekendGatewayDetail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWeekendGatewayDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.WeekendGatewayDetail = action.payload;
      state.error = "";
    });
    builder.addCase(getWeekendGatewayDetail.rejected, (state, action) => {
      state.loading = false;
      state.WeekendGatewayDetail = {};
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export default weekendGatewayDetailSlice.reducer;
