import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWeekendGatewayData } from "./weekenGatewaySlice";
import PageTitle from "../../components/PageTitle";
import { Helmet } from "react-helmet";
import WeekendGatewayTabing from "./WeekendGatewayTabing";
import { useLocation, useParams } from "react-router-dom";
import { getOrignalURL } from "../../common/helpers";
import { openDialog } from "../home/homeSlice";

const WeekendGatewaysPage = () => {
  const dispatch = useDispatch();
  const { WGUrl } = useParams();
  const location = useLocation();
  const fullURL = window.location.origin + location.pathname;

  const WeekendGatewayData = useSelector(
    (state) => state.weekendGateway.WeekendGatewayData
  );

  useEffect(() => {
    const fetchWeekendGateway = async () => {
      const res = await dispatch(
        getWeekendGatewayData({ DestinationURL: getOrignalURL(WGUrl) })
      );
      if (
        res?.payload === undefined ||
        (res?.payload?.URLWiseDestinationList?.ThreeStar?.length < 1 &&
          res?.payload?.URLWiseDestinationList?.FourStar?.length < 1 &&
          res?.payload?.URLWiseDestinationList?.FiveStar?.length < 1)
      ) {
        dispatch(openDialog());
      }
    };
    fetchWeekendGateway();

    // dispatch(getWeekendGatewayData({ DestinationURL: getOrignalURL(WGUrl) }));
  }, [location.pathname]);

  return (
    <>
      <Helmet>
        <title>{WeekendGatewayData?.MetaTitle}</title>
        <meta
          name="description"
          content={WeekendGatewayData?.MetaDescription}
        />
        <meta name="keywords" content={WeekendGatewayData?.MetaKeywords} />
        <link rel="canonical" href={fullURL} />
      </Helmet>
      <PageTitle
        BannerTitle={"Weekand Gateway"}
        BannerImage={WeekendGatewayData?.WGstaticBanner}
      />
      <WeekendGatewayTabing />
    </>
  );
};

export default WeekendGatewaysPage;
