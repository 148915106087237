import React from "react";
import OwlCarousel from "react-owl-carousel";
import { useSelector } from "react-redux";
import MAP_IMAGE from "../../assets/images/maps.png";
import { useNavigate } from "react-router-dom";

const SimilarPackages = () => {
  const TourDetails = useSelector((state) => state.packageDetail.TourDetails);
  const navigate = useNavigate();

  const handlePackageNavigate = (tour) => {
    if (tour?.TourURL) {
      navigate(`/package/${tour?.TourURL}/`, {
        state: { tourData: tour },
      });
    }
  };

  return (
    <div className="similer-packages">
      <h3>Similar Packages</h3>
      <div className="row simlar-div">
        {/* <OwlCarousel
        className="owl-carousel simlar-owl owl-theme arrow-top-right"
        loop
        margin={25}
        dots={false}
        nav
        autoplay={false}
        autoplayTimeout={4000}
        autoplayHoverPause={true}
        responsive={{
          0: {
            items: 1,
          },
          600: {
            items: 2,
          },
          1199: {
            items: 3,
          },
          1400: {
            items: 4,
          },
        }}
      > */}
        {TourDetails?.similarTour?.map(
          (tour, index) =>
            index <= 3 && (
              <div className="col-md-3 p-0 col-sm-6">
                <div className="item">
                  <div className="similarproduct-pic">
                    <img
                      className="cursor-pointer"
                      src={tour?.TourImage}
                      onClick={() => handlePackageNavigate(tour)}
                    />
                    <div className="days-desgin">
                      <span>{tour?.NoOfDays} Days</span>{" "}
                      <strong>{tour?.NoOfNights} Nights</strong>
                    </div>
                  </div>
                  <div className="similarproduct-data">
                    <h6
                      className="hoverable-title"
                      onClick={() => handlePackageNavigate(tour)}
                    >
                      {tour?.TourName}
                    </h6>
                    <div className="similter-city">
                      <img className="map-loc" src={MAP_IMAGE} />
                      <p>
                        <span>{tour?.TourCities}</span>
                      </p>
                    </div>
                    <div className="price-similar">
                      <strong>Package Starting From:</strong>
                      <span>₹{tour?.Rate}*</span>
                    </div>
                    <ul className="property-opti">
                      {tour?.toufacility &&
                        tour?.toufacility?.map((facility) => {
                          console.log("facility?.Image", facility?.Image);
                          return (
                            <li>
                              <img src={facility?.Image} />
                              <span>{facility?.Name}</span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            )
        )}
      </div>
      {/* </OwlCarousel> */}
    </div>
  );
};

export default SimilarPackages;
